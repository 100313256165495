import { fork } from 'redux-saga/effects';

import watchLogged from './auth/watchLogged';
import watchLoginWithPassword from './auth/watchLoginWithPassword';
import watchLogout from './auth/watchLogout';
import watchSignup from './auth/watchSignup';
import watchRecovery from './auth/watchRecovery';
import watchReset from './auth/watchReset';

import watchProfileSave from './profile/save';
import watchOrgFetch from './org/fetch';
import watchOrgSave from './org/save';
import watchAccountSave from './account/save';
import watchAccountRemove from './account/remove';

import watchEnrollmentSave from './enrollment/save';
import watchEnrollmentFetch from './enrollment/fetch';

// import watchRouter from './analytics';

import server from '../../services/server';

export default function* rootSaga() {

  yield fork(watchLogged, server);
  yield fork(watchLoginWithPassword, server);
  yield fork(watchLogout, server);
  yield fork(watchSignup, server);
  yield fork(watchRecovery, server);
  yield fork(watchReset, server);

  yield fork(watchEnrollmentSave, server);
  yield fork(watchEnrollmentFetch, server);

  yield fork(watchProfileSave, server);
  yield fork(watchOrgFetch, server);
  yield fork(watchOrgSave, server);
  yield fork(watchAccountSave, server);
  yield fork(watchAccountRemove, server);

  // yield fork(watchRouter);


}
