import reducer from '../reducers/SessionReducer';

export const LOGIN_USER_PASSWORD = 'LOGIN_USER_PASSWORD';
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

export function loginSuccess(dispatch) {
  return (payload) => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload,
      meta: {
        reducer,
      }
    });
  };
}

export function loginWithEmailAndPassword(dispatch) {
  return (payload) => {
    dispatch({
      type: LOGIN_USER_PASSWORD,
      payload,
    });
  };
}

export function recoveryPassword(dispatch) {
  return ({ email }) => {
    dispatch({
      payload: email,
      type: RECOVERY_PASSWORD
    });
  };
}

export function resetPassword(dispatch) {
  return ({ password, code }) => {
    dispatch({
      payload: { password, code },
      type: RESET_PASSWORD
    });
  };
}

export function signUp(dispatch) {
  return ({ displayName, email, agreement }) => {
    dispatch({
      payload: { displayName, email, agreement },
      type: SIGNUP
    });
  };
}
