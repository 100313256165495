import { takeLatest, put } from 'redux-saga/effects';
import { ORG_FETCH } from 'redux/actions/OrgActions';
import { actionOrgData } from 'redux/reducers/OrgReducer';

function* prepare(server, action) {
  try {
    const { data: org } = yield server.request(`/organizations/public/${action.payload}`);
    yield put( actionOrgData(org) );
  } catch (err) {
    // yield put(actionRemoveUser({}));
  }
}

export default function* watchOrgFetch(server) {
  yield takeLatest(ORG_FETCH, prepare, server);
}
