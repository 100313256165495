import reducer from '../reducers/UserReducer';

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGGED = "USER_LOGGED";

export function actionUserData(user) {
  return {
    type: SET_USER_DATA,
    data: user,
    meta: { reducer }
  }
}

export function actionRemoveUser(user) {
  return {
    type: REMOVE_USER_DATA,
    data: user,
    meta: { reducer }
  }
}

export function setUserData(dispatch) {
  return user => dispatch(actionUserData(user));
}

export function logoutUser(dispatch) {
  return () => {
    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}
