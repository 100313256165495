import { takeLatest, put, select } from "redux-saga/effects";
import { USER_LOGGED } from 'redux/actions/UserActions';
import { PROFILE_SAVE } from 'redux/actions/ProfileActions';
import { showInfo } from 'redux/reducers/InfoReducer';

function* prepare(server, action) {
  try {
    const { user: { id }, profile } = yield select(
      ({ user, profile }) => ({  user, profile })
    );
    yield server.put(`/users/profile/${id}`, { profile });
    yield put({
      type: USER_LOGGED,
    });
    const putin = showInfo(put);
    yield putin({
      open: true,
      message: 'Perfil atualizado com sucesso'
    })
  } catch (error) {
    // console.error(error);
    // yield put({
    //   type: SIGNUP_ERROR,
    //   payload: error,
    //   meta: {
    //     entity: 'user',
    //     reducer
    //   },
    //   ga: { category: 'ERROR', action: action.type }
    // });
  }
}

export default function* watchProfileSave(server) {
  yield takeLatest(PROFILE_SAVE, prepare, server);
}
