import React, { Component } from "react";
import { 
  CssBaseline,
  Container,
  Avatar,
  Button,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { resetPassword } from "redux/actions/SessionActions";
import Link from '@material-ui/core/Link';
import { Link as RouterLink, withRouter } from "react-router-dom";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '450px',
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    display: 'flex',
  }
});

class ResetPassword extends Component {
  state = {
    code: '',
    password: '',
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      ...this.props.match.params
    })
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    console.log(this.state);
    this.props.resetPassword(this.state);
  };

  render() {
    const { password } = this.state;
    const {classes, login: { message, loading, error } } = this.props;
    const errorBox = (error) ? 
      <Typography component="p" variant="body1">
        <Box color="error.main">{error}</Box>
      </Typography>: null;
    const successBox = (message) ? 
      <Typography component="p" variant="body1">
        <Box color="success.main">{message}</Box>
      </Typography>: null;

    return (
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <ValidatorForm
            className={classes.form}
            ref="form"
            onSubmit={this.handleFormSubmit}
          >
            <TextValidator
              className={classes.input}
              fullWidth
              label="Nova Senha"
              variant="outlined"
              onChange={this.handleChange}
              name="password"
              type="password"
              value={password}
              validators={["required"]}
              errorMessages={["Esse campo é obrigatório"]}
            />
            {successBox}
            {errorBox}
            <div className={classes.button}>
              <Button 
                variant="contained" 
                color="primary" 
                type="submit"
                disabled={loading}
              >
                Criar senha
              </Button>
              {loading && (
                  <CircularProgress
                    size={24}
                  />
                )}
            </div>
            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  component={RouterLink}
                  to="/session/signin"
                  variant="body2"
                >
                  Já tem conta? Entrar
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  layout: state.layout,
});
const mapDispatchToState = dispatch => ({
  resetPassword: resetPassword(dispatch)
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToState)(ResetPassword)
  )
);
