import axios from 'axios';

console.log('server URL', process.env.REACT_APP_SERVER_URL)

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
})
window.server = instance;

class Server {

  async post(url, data) {
    return await instance.post(url, data);
  }

  async put(url, data) {
    return await instance.put(url, data);
  }

  async fetch(url) {
    return await instance.get(url);
  }

  async request(json) {
    return await instance.request(json);
  }

}

export default new Server();