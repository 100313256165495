import React from "react";

const eventsRoutes = [
  {
    path: "/events/:id/enrollment",
    component: React.lazy(() => import('./Enrollment')),
  },
];

export default  eventsRoutes;
