import React from "react";

const orgsRoutes = [
  {
    path: "/orgs/new",
    component: React.lazy(() => import('./new/Org')),
  },
  {
    path: "/orgs/:id/edit",
    component: React.lazy(() => import('./edit/Edit')),
  },
  {
    path: "/orgs/:id/team",
    component: React.lazy(() => import('./team/Team')),
  },
  {
    path: "/orgs/:id",
    component: React.lazy(() => import('./view/View')),
  },
  {
    path: "/professor",
    component: React.lazy(() => import('./professor/Professor')),
  },
  {
    path: "/atleta",
    component: React.lazy(() => import('./atleta/Atleta')),
  },

];

export default  orgsRoutes;
