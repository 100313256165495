import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import Mobile from './mobile';
import Toolbar from './toolbar';
import Theme from './theme';
import { useSelector, useDispatch } from 'react-redux';
import { hideInfo } from 'redux/reducers/InfoReducer';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '68px',
    [theme.breakpoints.down('sm')]: {
      // marginTop: '58px',
      height: 'calc(100vh - 67px)',
    },
  }
}));

function Layout(props) {
  // const theme = useTheme();
  const classes = useStyles();
  const info = useSelector(state => state.info);
  const dispatch = useDispatch();
  const onClose = hideInfo(dispatch);
  
  // const sm = useMediaQuery(theme.breakpoints.up('sm'));
  // if(!sm) {
  //   return (
  //     <div>
  //       <Theme>
  //         <Toolbar />
  //         <Mobile>
  //           {props.children}
  //         </Mobile>
  //         <Snackbar
  //           open={info.open}
  //           onClose={onClose}
  //           autoHideDuration={3000}
  //         >
  //           <MuiAlert severity="success">
  //             {info.message}
  //           </MuiAlert>
  //         </Snackbar>
  //       </Theme>
  //     </div>
  //   )
  // }

  return (
    <div>
      <Theme>
        <Toolbar />
        <div className={classes.root}>
          {props.children}
        </div>
        <Snackbar
          open={info.open}
          onClose={onClose}
          autoHideDuration={3000}
        >
          <MuiAlert severity="success">
            {info.message}
          </MuiAlert>
        </Snackbar>
      </Theme>
    </div>
  )
}

export default Layout;