import { takeLatest, put } from 'redux-saga/effects';
import { USER_LOGGED_OUT, actionRemoveUser } from '../../actions/UserActions';
import { push } from 'connected-react-router';

function* prepareLogout(server, action) {
  try {
    yield server.fetch('/users/auth/logout');
    yield put(actionRemoveUser({}));
    yield put(push('/session/signin'));
    window.location.reload(true);
  } catch (err) {
    // yield put(genericErrors(action.type, err));
  }
}

export default function* watchLogout(server) {
  yield takeLatest(USER_LOGGED_OUT, prepareLogout, server);
}
