import { 
  PROFILE_ONCHANGE, 
  PROFILE_SAVE,
} from 'redux/actions/ProfileActions';

export const profileState = {
  displayName: '',
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "",
  phone: "",

  rg: '',
  cpf: '',
  dataNascimento: null,
  peso: '',
  categoria: '',
  estilo: '',
};

export function saveProfile(dispatch) {
  return () => {
    dispatch({ 
      type: PROFILE_SAVE, 
    });
  }
}

export function onChangeProfile(dispatch) {
  return (payload) => {
    dispatch({ 
      type: PROFILE_ONCHANGE, 
      payload,
      meta: { reducer: ProfileReducer }
    });
  }
}

const ProfileReducer = (state, action) => {
  switch (action.type) {
    case PROFILE_ONCHANGE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }
    default:
      return state;
  }
};

export default ProfileReducer