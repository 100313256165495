import { put, takeLatest } from 'redux-saga/effects';
import { 
  LOGIN_USER_PASSWORD,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  SIGNUP_ERROR
} from '../../actions/SessionActions';
import { actionUserData } from '../../actions/UserActions';
import reducer from '../../reducers/SessionReducer';
import { push } from 'connected-react-router';

function* loginUser(server, action) {
  try {
    yield put({ type: LOGIN_LOADING, meta: { reducer } });
    const { data: user } = yield server.post('/users/auth/login', action.payload);
    // window.location.reload(true); 
    yield put(actionUserData(user));
    yield put({ type: LOGIN_SUCCESS, meta: { reducer } });
    yield put(push('/home'));
    
  } catch (error) {
    console.error(error);
    yield put({
      type: SIGNUP_ERROR,
      payload: error,
      meta: {
        entity: 'user',
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchLogin(server) {
  yield takeLatest(LOGIN_USER_PASSWORD, loginUser, server);
}
