import { takeLatest, put, select } from 'redux-saga/effects';
import {
  ENROLLMENT_FETCH,
  ENROLLMENT_FETCH_SUCCESS,
} from 'redux/actions/EnrollmentActions';
import reducer from 'redux/reducers/EnrollmentReducer';

function* prepare(server, action) {
  try {
    const actionModel = {
      type: ENROLLMENT_FETCH_SUCCESS,
      meta: {
        reducer,
      }
    }
    const { user } = yield select();
    
    const { data: event } = yield server.fetch(`/events/${action.payload}`);

    let enrollment = null;
    if (user.id) {
      try {
        const { data } = yield server.fetch(`/events/enrollment/current`);
        enrollment = data;
      } catch (error) {

      }

    }

    yield put({
      ...actionModel,
      payload: {
        event,
        enrollment,
      }
    })


  } catch (err) {
    // yield put(actionRemoveUser({}));
  }
}

export default function*  watchEnrollmentFetch(server) {
  yield takeLatest(ENROLLMENT_FETCH, prepare, server);
}
