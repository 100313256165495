import { takeLatest, put } from 'redux-saga/effects';
import { 
  RESET_PASSWORD,
  SIGNUP_ERROR, 
  SIGNUP_SUCCESS, 
  LOGIN_LOADING, 
} from '../../actions/SessionActions';
import reducer from '../../reducers/SessionReducer';

function* prepare(server, action) {
  try {
    yield put({ type: LOGIN_LOADING, meta: { reducer } });
    yield server.post('/users/auth/confirm', action.payload);
    // TODO: lançar mensageem de sucesso na tela
    yield put({ 
      type: SIGNUP_SUCCESS, 
      payload: {
        message: "Senha criada com sucesso. \n Vá para a tela de entrada e digite o email e a nova senha."
      }, 
      meta: { reducer } 
    });
  } catch (err) {
    yield put({
      type: SIGNUP_ERROR,
      payload: err,
      meta: {
        entity: 'user',
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchReset(server) {
  yield takeLatest(RESET_PASSWORD, prepare, server);
}
