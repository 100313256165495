import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';
import Logout from './Logout';
import Logged from './Logged';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuItem: {
    textDecoration: 'none',
    color: theme.palette.common.white
  },
  button: {
    color: theme.palette.common.white,
    marginLeft: '10px',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonsBar: {
    marginBottom: 5,
  },
  logo: {
    height: 22,
    alignSelf: 'center',
  },
  home: {
    display: 'flex',
    
  }
}));

function AppToolbar(props) {

  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.home}>
        <img className={classes.logo} src="/assets/images/fewsp-logo.png" />
        <Typography variant="h6" className={classes.title}>
          <Link className={classes.menuItem} to="/">
            FEWSP
          </Link>
        </Typography>
      </div>
      <div className={classes.buttonsBar}>
        { 
          (props.user.id) ? 
            <Logged classes={classes} /> : <Logout classes={classes} /> 
        }
      </div>

    </Toolbar>
  )
}
const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(AppToolbar);