import { takeLatest, put, select } from "redux-saga/effects";
import { ATHLETE_SUBMIT } from 'redux/actions/AthleteActions';
import { showInfo } from 'redux/reducers/InfoReducer';
import { actionOrgData } from 'redux/reducers/OrgReducer';

function* prepare(server, action) {
  try {
    const { data: org } = yield server.post(`/organizations/${action.payload.id}/account`, {});
    
    yield put(actionOrgData(org))

    const putin = showInfo(put);
    yield putin({
      open: true,
      message: 'Solicitação enviada com sucesso'
    })


  } catch (error) {
    // console.error(error);
    // yield put({
    //   type: SIGNUP_ERROR,
    //   payload: error,
    //   meta: {
    //     entity: 'user',
    //     reducer
    //   },
    //   ga: { category: 'ERROR', action: action.type }
    // });
  }
}

export default function* watchAccountSave(server) {
  yield takeLatest(ATHLETE_SUBMIT, prepare, server);
}
