import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';
import { initialState as login } from "./SessionReducer";
import { REMOVE_USER_DATA } from '../actions/UserActions';
import { profileState as profile } from './ProfileReducer';
import { infoState as info } from './InfoReducer';
import { orgState as org } from './OrgReducer';

export const initialState = {
  initialUrl: null,
  user: {},
  login,
  layout: {
    logo: ''
  },
  profile,
  info,
  org,
  members: [], 
  geodata: [],
  event: null,
  enrollment: null,
}

const createReducer = history => {
  const fn = connectRouter(history);

  return (state = initialState, action) => {

    if (action.type === LOCATION_CHANGE) {
      const { pathname } = action.payload.location;
      return {
        ...state,
        initialUrl: !state.initialUrl ? pathname : state.initialUrl,
        router: fn({
          ...action.payload,
          user: state.user
        })
      };
    }

    if (action.type === REMOVE_USER_DATA) {
      return {
        ...state,
        login: {
          ...state.login,
          logged: true,
        },
        user: {},
      };
    }

    if (action.meta && action.meta.reducer) {
      return action.meta.reducer(state, action);
    }

    return state;
  };
};

export default createReducer;