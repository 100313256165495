import React from 'react';
import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

function Logout(props) {
  const { goToSignIn, goToSignUp, classes } = props;
  return (
    <div>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={goToSignIn}
      >
        Entrar
      </Button>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={goToSignUp}
      >
          Cadastrar
      </Button>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    goToSignIn: () => dispatch(push(`/session/signin`)),
    goToSignUp: () => dispatch(push(`/session/signup`)),
  }
}

export default connect(null, mapDispatchToProps)(Logout);