import { takeLatest, put, select } from "redux-saga/effects";
import { ATHLETE_REMOVE } from 'redux/actions/AthleteActions';
import { showInfo } from 'redux/reducers/InfoReducer';
import { actionOrgData, orgState as org } from 'redux/reducers/OrgReducer';

function* prepare(server, action) {
  try {
    yield server.request({
      method: 'delete',
      url: `/organizations/${action.payload.id}/account`,
    });

    yield put(actionOrgData({
      id: null
    }))

    const putin = showInfo(put);
    yield putin({
      open: true,
      message: 'Exclusão enviada com sucesso'
    })
  } catch (error) {
    console.error(error);
  }
}

export default function* watchAccountRemove(server) {
  yield takeLatest(ATHLETE_REMOVE, prepare, server);
}
