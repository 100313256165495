import { 
  ENROLLMENT_SAVE,
} from 'redux/actions/EnrollmentActions';

export const profileState = {
  displayName: '',
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  cidade: "",
  uf: "",
  phone: "",
  rg: '',
  cpf: '',
  dataNascimento: null,
  peso: '',
  categoria: '',
  estilo: '',
  nomeMae: '',
  nomePai: '',
};

export function saveEnrollment(dispatch) {
  return (payload) => {
    dispatch({ 
      type: ENROLLMENT_SAVE, 
      payload,
    });
  }
}

const EnrollmentReducer = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
  // switch (action.type) {
  //   case INFO_SHOW:
  //     return {
  //       ...state,
  //       info: action.payload
  //     }
  //   case INFO_HIDE:
  //     return {
  //       ...state,
  //       info: infoState,
  //     }
  //   default:
  //     return state;
  // }
};

export default EnrollmentReducer;