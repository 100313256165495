import React from 'react';
import {
  Button,
  IconButton,
  MenuItem,
  Menu,
  Link,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';

import AccountCircle from '@material-ui/icons/AccountCircle';
import { connect, useDispatch } from "react-redux";
import { logoutUser } from 'redux/actions/UserActions';
import { Link as RouterLink } from "react-router-dom";
import { push } from 'connected-react-router';

function Logged({ classes }) {

  const dispatch = useDispatch();

  return (
    <div>
      <IconButton
        size="small" 
        aria-label="home"
        onClick={() => dispatch(push(`/`))}
      >
        <HomeIcon />
      </IconButton>
      <Button
        size="small"
        variant="outlined"
        className={classes.button}
        onClick={() => dispatch(push(`/professor`))}
      >
        Professor
      </Button>
      <Button
        size="small"
        variant="outlined"
        className={classes.button}
        onClick={() => dispatch(push(`/atleta`))}
      >
        Atleta
      </Button>
      <Button
        size="small"
        variant="outlined"
        className={classes.button}
        onClick={() => dispatch(push(`/profile`))}
      >
        Perfil
      </Button>

    </div>
  )

}

export default Logged

// class Logged extends React.Component {

//   state = {
//     anchorEl: null
//   }

//   render() {

//     const { anchorEl } = this.state;
//     const open = Boolean(anchorEl);

//     const handleMenu = (event) => {
//       this.setState({
//         anchorEl: event.currentTarget
//       })
//     };

//     const handleClose = (event) => {
//       this.setState({
//         anchorEl: null
//       }, () => {
//         if (event.target.dataset?.path) {
//           this.props.goTo(event.target.dataset?.path)
//         }
//       })
//     };

//     const handleSignOut = () => {
//       this.props.logoutUser();
//     };

//     return (
//       <div>
//         <IconButton
//           aria-label="account of current user"
//           aria-controls="menu-appbar"
//           aria-haspopup="true"
//           onClick={handleMenu}
//           color="inherit"
//         >
//           <AccountCircle />
//         </IconButton>
//         <Menu
//           anchorEl={anchorEl}
//           anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//           keepMounted
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//           }}
//           open={open}
//           onClose={handleClose}
//         >
//           <MenuItem onClick={handleClose} data-path="/professor">
//             Sou Professor
//           </MenuItem>
//           <MenuItem onClick={handleClose} data-path="/atleta">
//             Sou Atleta
//           </MenuItem>
//           <MenuItem onClick={handleClose} data-path="/profile">
//             Meus dados
//           </MenuItem>
//           <MenuItem onClick={handleSignOut}>Sair</MenuItem>
//         </Menu>
//       </div>
//     )
//   }
// }

// const mapStateToProps = state => ({

// });
// const mapDispatchToProps = dispatch => ({
//   goTo: path => dispatch(push(path)),
//   logoutUser: logoutUser(dispatch),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(Logged);
