import {
  SET_USER_DATA,
} from "../actions/UserActions";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA: {
      const user = action.data;
      const profile = {
        ...state.profile,
        displayName: user.displayName,
        ...(user.profile? user.profile : {})
      };
      return {
        ...state,
        profile,
        login: {
          ...state.login,
          logged: true,
        },
        user: {
          ...state.user,
          ...user,
        },
      };
    }

    default: {
      return state;
    }
  }
};