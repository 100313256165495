import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';

const themeBase = {
  palette: {
    // type: "dark",
    primary: {
      main: red[500],
    },
    secondary: {
      main: grey[500],
    },
  },

};

const theme = createMuiTheme(themeBase);

function Theming(props) {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  );
}

export default Theming;