import { takeLatest, put, select } from "redux-saga/effects";
import { ORG_SAVE } from 'redux/actions/OrgActions';
import { showInfo } from 'redux/reducers/InfoReducer';

function* prepare(server, action) {
  try {
    const { org } = yield select(({ org }) => ({ org }));
    yield server.put(`/organizations/${org.id}`, { org });
    const putin = showInfo(put);
    yield putin({
      open: true,
      message: 'Filiada atualizada com sucesso'
    })
  } catch (error) {
    // 
  }
}

export default function* watchOrgSave(server) {
  yield takeLatest(ORG_SAVE, prepare, server);
}
