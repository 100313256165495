import { 
  ORG_ONCHANGE, 
  ORG_ONCHANGE_NAME,
  ORG_SAVE,
  ORG_FETCH,
} from 'redux/actions/OrgActions';

export const orgState = {
  name: '',
  profile: {
    cep: "",
    endereco: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: "",
    phone: "",
  }
};

export function actionOrgData(payload) {
  return { 
    type: ORG_ONCHANGE_NAME, 
    payload,
    meta: { reducer: OrgReducer },
  }
}

export function saveOrg(dispatch) {
  return () => {
    dispatch({ 
      type: ORG_SAVE, 
    });
  }
}

export function fetchOrg(dispatch) {
  return (payload) => {
    dispatch({ 
      type: ORG_FETCH, 
      payload, 
    });
  }
}

export function onChangeOrg(dispatch) {
  return (payload) => {
    dispatch({ 
      type: ORG_ONCHANGE, 
      payload,
      meta: { reducer: OrgReducer },
    });
  }
}

export function onChangeOrgName(dispatch) {
  return (payload) => {
    dispatch(actionOrgData(payload));
  }
}

const OrgReducer = (state, action) => {
  switch (action.type) {
    case ORG_ONCHANGE_NAME: 
      return {
        ...state,
        org: {
          ...state.org,
          ...action.payload,
        },
      }
    case ORG_ONCHANGE:
      return {
        ...state,
        org: {
          ...state.org,
          profile: {
            ...state.org.profile,
            ...action.payload,
          },
        },
      }
    default:
      return state;
  }
};

export default OrgReducer