import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { loginWithEmailAndPassword, loginSuccess } from "redux/actions/SessionActions";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class SignIn extends React.Component {

  state = {
    email: '',
    password: '',
    remember: true,
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleToggleChange = event => {
    event.persist();
    console.log( event.target.checked)
    this.setState({
      'remember': event.target.checked
    });
  };
  handleFormSubmit = event => {
    this.props.loginWithEmailAndPassword({ ...this.state });
  };

  componentWillUnmount() {
    this.props.reset()
  }

  render() {
    let { email, password, remember } = this.state;
    let { classes, login: { error, message } } = this.props;

    console.log('error', remember);

    const errorBox = (error) ? 
      <Typography component="p" variant="body1">
        <Box color="error.main">{error}</Box>
      </Typography>: null;
    const successBox = (message) ? 
    <Typography component="p" variant="body1">
      <Box color="success.main">{message}</Box>
    </Typography>: null;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <ValidatorForm
            className={classes.form}
            ref="form"
            onSubmit={this.handleFormSubmit}
          >
            <TextValidator
              className={classes.input}
              fullWidth
              variant="outlined"
              label="Email"
              onChange={this.handleChange}
              type="email"
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[
                "campo obrigatório",
                "email não é válido"
              ]}
            />
            <TextValidator
              className={classes.input}
              fullWidth
              label="Senha"
              variant="outlined"
              onChange={this.handleChange}
              name="password"
              type="password"
              value={password}
              validators={["required"]}
              errorMessages={["campo obrigatório"]}
            />
            <FormControlLabel
              control={
                <Checkbox 
                  checked={remember}
                  color="primary"
                  onChange={this.handleToggleChange}
                />
              }
              label="Lembrar"
            />

            {successBox}
            {errorBox}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={this.props.login.loading}
              className={classes.submit}
            >
              Entrar
            </Button>
            {this.props.login.loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}
          </ValidatorForm>

            <Grid container>
              <Grid item xs>
                <Link
                  component={RouterLink}
                  to="/session/forgot-password"
                  variant="body2"
                >
                  Esqueceu a senha?
                </Link>
              </Grid>
              <Grid item>
                <Link
                  component={RouterLink}
                  to="/session/signup"
                  variant="body2"
                >
                  Não tem conta? Cadastrar
                </Link>
              </Grid>
            </Grid>

        </div>
      </Container>
    );

  }

}

const mapStateToProps = state => ({
  login: state.login,
  layout: state.layout,
});
const mapDispatchToProps = dispatch => ({
  loginWithEmailAndPassword: loginWithEmailAndPassword(dispatch),
  reset: loginSuccess(dispatch),
});
export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
);
