import { takeLatest, put } from 'redux-saga/effects';
import {
  LOGIN_LOADING,
  SIGNUP_ERROR, 
  SIGNUP_SUCCESS, 
  RECOVERY_PASSWORD,
} from '../../actions/SessionActions';
import reducer from '../../reducers/SessionReducer';

function* prepare(server, action) {
  try {
    yield put({ type: LOGIN_LOADING, meta: { reducer } });
    const { payload: email} = action;
    yield server.post('/users/auth/reset', { email });
    // TODO: lançar mensageem de sucesso na tela
    yield put({ 
      type: SIGNUP_SUCCESS, 
      payload: {
        message: "Senha enviada com sucesso, confira seu email"
      }, 
      meta: { reducer } 
    });

  } catch (err) {
    yield put({
      type: SIGNUP_ERROR,
      payload: err,
      meta: {
        entity: 'user',
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchRecovery(server) {
  yield takeLatest(RECOVERY_PASSWORD, prepare, server);
}
