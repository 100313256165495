import { 
  INFO_SHOW, 
  INFO_HIDE,
} from 'redux/actions/InfoActions';

export const infoState = {
  open: false,
  message: '',
};

export function showInfo(dispatch) {
  return (payload) => {
    return dispatch({ 
      type: INFO_SHOW, 
      payload,
      meta: { reducer: InfoReducer }
    });
  }
}

export function hideInfo(dispatch) {
  return () => {
    return dispatch({ 
      type: INFO_HIDE, 
      meta: { reducer: InfoReducer }
    });
  }
}

const InfoReducer = (state, action) => {
  switch (action.type) {
    case INFO_SHOW:
      return {
        ...state,
        info: action.payload
      }
    case INFO_HIDE:
      return {
        ...state,
        info: infoState,
      }
    default:
      return state;
  }
};

export default InfoReducer;