import './App.css';

import React from 'react';

import { createBrowserHistory } from 'history';
import { renderRoutes } from 'react-router-config';
import { Switch } from 'react-router-dom';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import createReducer from './redux/reducers';
import sagas from './redux/sagas';
import { USER_LOGGED } from 'redux/actions/UserActions';

import Layout from './components/layout';
import Suspense from './components/Suspense';
import routes from './views/RootRoutes';

const browserHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [routerMiddleware(browserHistory), sagaMiddleware];
const composeEnhancers = composeWithDevTools({});
const composed = composeEnhancers(applyMiddleware(...middlewares));
const reducer = createReducer(browserHistory);
const Store = createStore(reducer, composed);

sagaMiddleware.run(sagas);

class App extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      Store.dispatch({
        type: USER_LOGGED
      })
    }, 400)
  }

  render() {
    return (
      <Provider store={Store}>
        <div className="App">
          <ConnectedRouter history={browserHistory}>
            <Layout>
              <Switch>
                <Suspense>
                  {renderRoutes(routes)}
                </Suspense> 
              </Switch>
            </Layout>
          </ConnectedRouter>
        </div>
      </Provider>
    );
  }
}

export default App;
