import React, { Component } from "react";
import { 
  CssBaseline,
  Container,
  Avatar,
  Button,
  Typography,
  Box, 
  CircularProgress,
  Grid,
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { recoveryPassword } from "redux/actions/SessionActions";
import Link from '@material-ui/core/Link';
import { Link as RouterLink, withRouter } from "react-router-dom";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '450px',
    marginTop: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    display: 'flex',
  }
});

class ForgotPassword extends Component {
  state = {
    email: ''
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleFormSubmit = () => {
    this.props.recoveryPassword({ ...this.state });
  };
  render() {
    const { email } = this.state;
    const { classes, login: { loading, error, message } } = this.props;
    const errorBox = (error) ? 
    <Typography component="p" variant="body1">
      <Box color="error.main">{error}</Box>
    </Typography>: null;

    const successBox = (message) ? 
    <Typography component="p" variant="body1">
      <Box color="success.main">{message}</Box>
    </Typography>: null;

    return (
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <ValidatorForm 
            className={classes.form}
            ref="form" 
            onSubmit={this.handleFormSubmit}
          >
            <TextValidator
              className={classes.input}
              fullWidth
              variant="outlined"
              label="Email"
              onChange={this.handleChange}
              type="email"
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              errorMessages={[
                "Esse campo é obrigatório",
                "Email não é válido"
              ]}
            />
            {successBox}
            {errorBox}
            <div className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Recuperar Senha
              </Button>
              {loading && (
                  <CircularProgress
                    size={24}
                  />
                )}
            </div>

            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  component={RouterLink}
                  to="/session/signin"
                  variant="body2"
                >
                  Já tem conta? Entrar
                </Link>
              </Grid>
            </Grid>

          </ValidatorForm>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  layout: state.layout,
});
const mapDispatchToState = dispatch => ({
  recoveryPassword: recoveryPassword(dispatch)
});

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToState)(ForgotPassword)
  )
);
