import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  RESET_PASSWORD,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
} from "../actions/SessionActions";

export const initialState = {
  logged: false,
  success: false,
  loading: false,
  error: null,
};

const LoginReducer = function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        }
      };
    }
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS: {
      return {
        ...state,
        login: {
          ...state.login,
          error: false,
          success: true,
          loading: false,
          ...action.payload,
        }
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        login: {
          ...state.login,
          success: true,
          loading: false
        }
      };
    }
    case SIGNUP_ERROR:
    case LOGIN_ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          success: false,
          loading: false,
          error: (action.payload && action.payload.response) ? action.payload.response.data.message : "",
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
