import { takeLatest, put, select } from "redux-saga/effects";

import { USER_LOGGED } from 'redux/actions/UserActions';
import { ENROLLMENT_SAVE, ENROLLMENT_FETCH } from 'redux/actions/EnrollmentActions';
import { showInfo } from 'redux/reducers/InfoReducer';

function* prepare(server, action) {
  try {
    const { profile, event, user } = action.payload;
    // yield server.post(`/events/${event.id}/apply`); 

    yield server.post(`/events/enrollments/submit`, { 
      enrollment: {
        name: profile.displayName,
        orgId: user.accounts[0].orgId,
        eventId: event.id,
        isSubmitted: true,
        createdBy: user.email,
        lastChangedBy: user.email,
        enrollmentSettings: {
          ...profile,
          userId: user.id,
          email: user.email,
        },
      }
     });
    yield put({
      type: USER_LOGGED,
    });
    const putin = showInfo(put);
    yield putin({
      open: true,
      message: 'Inscrição enviada com sucesso'
    })
    yield put({ 
      type: ENROLLMENT_FETCH,
      payload: event.id,
    })
  } catch (error) {
    // console.error(error);
    // yield put({
    //   type: SIGNUP_ERROR,
    //   payload: error,
    //   meta: {
    //     entity: 'user',
    //     reducer
    //   },
    //   ga: { category: 'ERROR', action: action.type }
    // });
  }
}

export default function* watchEnrollmentSave(server) {
  yield takeLatest(ENROLLMENT_SAVE, prepare, server);
}
