import { takeLatest, put } from 'redux-saga/effects';
import {
  LOGIN_LOADING,
  SIGNUP, 
  SIGNUP_ERROR, 
  SIGNUP_SUCCESS 
} from '../../actions/SessionActions';
import reducer from '../../reducers/SessionReducer';
import { push } from 'connected-react-router';

function* prepare(server, action) {
  try {
    yield put({ type: LOGIN_LOADING, meta: { reducer } });
    yield server.post('/users/auth/signup', action.payload);
    yield put(push('/session/signin'));
    const message = `
      Usuário criado com sucesso!
      Conferir o link no email para confirmar e criar sua senha.
    `;
    yield put({ 
      type: SIGNUP_SUCCESS, 
      payload: { message }, 
      meta: { reducer } 
    });
  } catch (err) {
    yield put({
      type: SIGNUP_ERROR,
      payload: err,
      meta: {
        entity: 'user',
        reducer
      },
      ga: { category: 'ERROR', action: action.type }
    });
  }
}

export default function* watchSignup(server) {
  yield takeLatest(SIGNUP, prepare, server);
}
