import { takeLatest, put } from 'redux-saga/effects';
import {
  USER_LOGGED,
  actionUserData, 
  actionRemoveUser,
} from '../../actions/UserActions';

function* prepare(server, action) {
  try {
    const { data: loggedUser } = yield server.fetch('/users/auth/logged');
    yield put( actionUserData(loggedUser) );
  } catch (err) {
    yield put(actionRemoveUser({}));
  }
}

export default function* watchSignup(server) {
  yield takeLatest(USER_LOGGED, prepare, server);
}
