import React from "react";

const profilesRoutes = [
  {
    path: "/profile",
    component: React.lazy(() => import("./Profile"))
  }
];

export default  profilesRoutes;
