import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  CircularProgress,
} from "@material-ui/core";

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { signUp, loginSuccess } from 'redux/actions/SessionActions';
import { connect } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});


class SignUp extends React.Component {
  state = {
    displayName: "",
    email: "",
    agreement: true
  };

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleChangeChecked = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.checked
    });
  };

  handleFormSubmit = event => {
    this.props.signUp(this.state)
  };

  componentWillUnmount() {
    this.props.reset()
  }

  render() {
    const { displayName, email, agreement } = this.state;
    const { classes, login: { error } } = this.props;
    const errorBox = (error) ? 
    <Typography component="p" variant="body1">
      <Box color="error.main">{error}</Box>
    </Typography>: null;

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <ValidatorForm 
            ref="form"
            onSubmit={this.handleFormSubmit}
          >

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="fname"
                  name="displayName"
                  variant="outlined"
                  required
                  fullWidth
                  label="Nome"
                  autoFocus
                  value={displayName}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      name="agreement"
                      checked={agreement}
                      onChange={this.handleChangeChecked}
                      required
                    />
                  }
                  label={
                    <Typography>
                      Eu declaro acordo com os {' '}
                      <Link
                        target="_blank"
                        rel="noopener" 
                        color="primary"
                        component={RouterLink}
                        to="/session/termos"
                      >
                        Termos de Uso
                      </Link>
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid>
            </Grid>

            {errorBox}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Cadastrar
            </Button>
            {this.props.login.loading && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
              />
            )}

            <Grid container justify="flex-end">
              <Grid item>
                <Link
                  component={RouterLink}
                  to="/session/signin"
                  variant="body2"
                >
                  Já tem conta? Entrar
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>

        </div>

      </Container>
    );
  }
}


const mapStateToProps = state => ({
  login: state.login,
  // layout: state.layout,
})

const mapDispatchToProps = dispatch => ({
  signUp: signUp(dispatch),
  reset: loginSuccess(dispatch),
});

export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
);